


























import Vue from "vue";
import { mapActions } from "vuex";
import { required, sameAs } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

import AuthLayout from "@/components/auth/Layout.vue";

export default Vue.extend({
  components: {
    AuthLayout,
  },
  name: "ResetPassword",
  data: () => {
    return {
      password: "",
      repeatPassword: "",
      otp: "",
      processing: false,
    };
  },
  methods: {
    ...mapActions([
      "displayMessage",
      "resetPassword",
      "showLoading",
      "hideLoading",
    ]),
    onOtpNotObtained() {
      this.$router.replace({
        name: "Home",
      });
    },
    async getOtp(): Promise<void> {
      try {
        const otp = this.$route.query["otp"] as string;
        if (otp) {
          this.otp = otp;
          this.$router.replace({ query: {} });
        } else {
          this.onOtpNotObtained();
        }
      } catch (err) {
        this.onOtpNotObtained();
      }
    },
    async reset() {
      if (this.processing) return;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.processing = true;

        await this.resetPassword({ otp: this.otp, password: this.password });

        this.displayMessage("The password was reset successfully. ");

        this.processing = false;
        this.hideLoading();

        this.$router.push({
          name: "Home",
        });
      } catch (err) {
        this.processing = false;
      }
    },
    getErrors(name: string, model: any): Array<string> {
      const errors = new Array<string>();
      if (!model.$dirty) return errors;

      switch (name) {
        case "password":
          !model.required && errors.push("New Password is required");
          break;
        case "repeatPassword":
          !model.sameAsPassword &&
            errors.push(
              "The Password here is not the same as the password on the top"
            );
          break;
        default:
          break;
      }
      return errors;
    },
  },
  mounted() {
    this.getOtp();
  },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
    },
    repeatPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  watch: {
    processing: function (val) {
      val ? this.showLoading() : this.hideLoading();
    },
  },
});
